@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'muli-regular';
  src: url('/public/fonts/Muli.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muli-bold';
  src: url('/public/fonts/Muli-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muli-semibold';
  src: url('/public/fonts/Muli-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'muli-black';
  src: url('/public/fonts/Muli-Black.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.font-black {
  font-family: 'muli-black';
}

.font-semibold {
  font-family: 'muli-semibold';
}

.font-bold {
  font-family: 'muli-bold';
} */

body {
  /* font-family: 'muli-regular'; */
  font-family: 'Inter', sans-serif;
}

.intialPos {
  position: initial !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.font-kalam{
  font-family: 'Inter', sans-serif !important;
}
.fil-gr{
  filter: grayscale(1);
}

nav .links{
  display: flex;
  align-items: center;
  gap: 40px;
}

nav .links li{
  font-weight: 500;
  position: relative;
}
nav .links li.dropdown span{
  display: inline-block;
  padding-right: 18px;
  background-image: url(../src/images/arrow-down.png);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: right center;
}
nav .links li.dropdown .submenu{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 25px;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.3s;
  background: #28B467;
  width: 200px;
  border-radius: 8px;
  padding: 10px 4px;
  z-index: 2;
}

nav .links li.dropdown .submenu li a{
  color: #fff;
  padding: 10px 15px;
  text-align: left;
  display: block;
  height: auto;
  width: 100%;
  transition: all ease-in-out 0.3s;
}

nav .links li.dropdown .submenu li a:hover{
  background: #fff;
  color: #28B467;
}

nav .links li.dropdown:hover .submenu{
  visibility: visible;
  opacity: 1;
}

.sec-1:before{
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  content: '';
  opacity: 0.56;
  top: 50%;
  background: #28B467;
  filter: blur(99px);
}

.sec .inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sec .inner .img-wrap{
  width: calc(100% - 650px);
  position: relative;
}
.pattern{
  position: relative;
}
.logo-wrap img{
  width: 40px;
}
.sec .inner .img-wrap:before, .pattern:before{
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  content: '';
  opacity: 0.56;
  top: 0;
  background: #28B467;
  filter: blur(99px);
  z-index: 1;
}

.pattern:before{
  top: -60px;
  opacity: 0.32;
background: #28B467;
filter: blur(50px);
}
.sec-1 .desk-img{
  border-radius: 10px 0 0 10px;
}
.sec-4 .box, .sec-7 .box, .sec-5 .box{
  border-radius: 16px;
  background: #FFF;
  padding: 32px 15px;
  box-shadow: 0px 24px 64px 0px rgba(24, 24, 27, 0.04);
}
.sec-5 .box img{
  height: 326px;
}
.sec-5{
  position: relative;
}
.sec-5:before{
  position: absolute;
  content: '';
  width: 100%;
  max-width: 944px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 565px;
  opacity: 0.1;
  background: #28B467;
  filter: blur(99px);
}

.sec-7 .box{
  padding: 48px 20px;
}

.bg-gradient{
  background: #28B467;
}

.sec-5 .img-wrap{
  border-radius: 8px;
  background: #28B467;
}

.sec-5 .img-wrap img{
  mix-blend-mode: multiply;
}


footer .inner{
  display: flex;
  justify-content: space-between;
}

footer .inner .left p{
  margin-top: 24px;
}

footer .inner .links-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 90px;
}

footer .inner .links-wrapper .wrap span{
  color: #6A6A77; 
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px; /* 170% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 26px;
}


footer .inner .left p, footer .inner .links-wrapper ul li a, footer .copyright p, footer .copyright a{
  color: #4D4D56;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.8px; /* 170% */
  letter-spacing: -0.09px;
}

footer .inner .links-wrapper ul li{
  margin-bottom: 20px;
}

footer .copyright{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  border-top: 1px solid #ECECEE;
  margin-top: 60px;
}

footer .copyright span{
  color: #18181B;
  font-weight: 500;
}

.popup-wrap{
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  position: fixed;
  background: #fff;
  z-index: 9999999999999999;
  width: 100%;
  top: 0;
}

.profile-wrap{
  margin-top: 80px;
}

.profile-wrap h2, .statistics-chart-wrapper h2{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}

.profile-wrap .boxes{
  display: flex;
  margin: 0 -15px 60px;
  flex-wrap: wrap;
}

.profile-wrap .boxes .box{
  width: calc(25% - 30px);
  margin: 0 15px 30px;
  text-align: center;
  border-radius: 5px;
background: #FFF;
padding: 32px;
box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
}


.profile-wrap .boxes .box img{
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.profile-wrap .boxes .box h2{
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 18px;
}

.profile-wrap .boxes .box p{
  color: #A3A3A3;
font-size: 16px;
letter-spacing: 0.042px;

}

/* Statistics */
.statistics-chart-wrapper {
  margin-top: 150px;
}

.statistics-chart-wrapper .chart-wrap{
  border-radius: 32px;
  background: rgb(231 251 249);
  padding: 32px 32px;
}

.statistics-chart-wrapper .chart-wrap .apexcharts-yaxis-label,
.statistics-chart-wrapper .chart-wrap .apexcharts-xaxis-label{
  color: #27313B;
  
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.statistics-chart-wrapper .chart-wrap .apexcharts-title-text{
  color: #27313B;
  font-size: 24px;
  font-weight: 600;
}

.statistics-chart-wrapper .chart-wrap .apexcharts-toolbar{
  display: none;
}

.course-chart-info{
  display: flex;
  margin: 6px -24px -12px;
}

.course-chart-info .no-data{
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
}

.course-chart-info .info{
  width: calc(33.33% - 48px);
  margin: 12px 24px;
  border-radius: 100px;
  background: #18181b;
  text-align: center;
  padding: 24px 12px;
}

.course-chart-info .info p{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
}

.profile-link{
  display: flex;
  height: 56px;
  width: auto;
  align-items: center;
  justify-content: center;
}
.filewrap{
  position: relative;
}
.close-modal{
  position: absolute;
 top: 20px;
 right: 35px;
 filter: grayscale(100%);
 cursor: pointer;
}

.filewrap input{
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 0;
  position: absolute;
}

.filewrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.filewrap span{
  color: #a0a7b2;
}

.filewrap .upload-btn{
  background: transparent;
  border: 1px solid #272A33;
  border-radius: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 10px;
}

.filewrap .upload-btn img{
  margin-right: 5px;
}

.popup-wrap #veriff-root{
  max-width: 100%;
}

.popup-wrap h2{
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 4px;
}

 .popup-wrap p{
  font-size: 1rem;
  line-height: 1.3rem;
}

#veriff-root input{
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 1.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-radius: 0.75rem;
  background-color: rgb(245 245 245);
  box-shadow: none;
  border-color: #e5e7eb;
}

#veriff-root input[type="submit"]{
  background: #28B467;
  cursor: pointer;
}

#veriff-root  .veriff-description a{
  font-size: 15px;
}

#veriff-root  .veriff-description{
  margin: 0;
}
/* Popular Collection */

.popular-collection {
  padding: 120px 0 70px;
  overflow: hidden;
}

.collection-box .images-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.collection-box .images-wrapper img {
  display: flex;
  flex-wrap: wrap;
  width: calc(33.33% - 10px);
  margin: 5px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
  height: 131px;
}

.collection-box .images-wrapper>img:first-child,
.collection-box .images-wrapper>img:nth-child(2) {
  width: calc(50% - 10px);
}

.popular-collection .collection-box .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popular-collection .collection-box .content .left {
  display: flex;
  align-items: flex-start;
}

.profile .popular-collection .collection-box .content .left {
  width: 100%;
}

.popular-collection .collection-box .content .img-wrap {
  position: relative;
}

.popular-collection .collection-box .content .img-wrap img {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.popular-collection .collection-box .content .img-wrap img.top-seller-mark {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.popular-collection .collection-box .content .info {
  padding-left: 10px;
  width: calc(100% - 64px);
}

.popular-collection .collection-box .content .info h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.popular-collection .collection-box .content .info p {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #EBEBEB;
}

.popular-collection .collection-box .content .info p span {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #666666;
}

.popular-collection.sell .collection-box .content {
  padding: 0 0 20px;
  align-items: flex-start;
}

.popular-collection.sell .collection-box .images-wrapper img {
  height: auto;
}

.collection-box .images-wrapper .inner-images-wrap {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.collection-box .images-wrapper .inner-images-wrap img {
  width: calc(50% - 10px);
}

.collection-box .images-wrapper .inner-images-wrap img:last-child {
  width: 100%;
}

.collection-row{
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}
.collection-row .collection-box{
  margin: 15px;
  width: calc(33.33% - 30px);
  padding: 16px;
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.collection-box .img-wrap {
  position: relative;
}
.collection-box .images-wrapper .inner-images-wrap {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.btn-wrap{
  max-width: 680px;
  margin: 0 auto;
  width: 100%;
}

.btn-wrap.pattern:before{
  transform: rotate(180deg);
  top: auto;
  bottom: -50px;
  z-index: -1;
}

/* Popular Collection End */

/* Explore */

.explore {
  padding: 120px 0 70px;
  overflow: hidden;
}
.sidebar{
  width: 264px;
}

.sidebar .accordion-header {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #fff;
  background-color: transparent;
  border: 0 !important;
}

.sidebar .accordion-item {
  background-color: transparent;
  border: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid #343444;
  border-radius: 0;
  margin-bottom: 24px;
}

.sidebar .accordion-header button {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #39343A;
  background-color: transparent;
  padding: 0;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  position: relative;
  width: 100%;
  text-align: left;
}

.sidebar .accordion-header button:after {
  background-image: url(../src/images/arrow-down.png);
  background-size: 20px;
  position: absolute;
  content: '';
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  right: 0;
  top: 4px;
}

.sidebar ul {
  padding-top: 12px;
}

.sidebar li input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.sidebar .accordion-collapse {
  border-radius: 0 !important;
}

.sidebar li label {
  position: relative;
  cursor: pointer;
  color: #39343A;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sidebar li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.sidebar li:last-child {
  margin-bottom: 0;
}

.sidebar li label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #7A798A;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.sidebar li input:checked+label:before {
  border: 1px solid #28B467;
  background: #28B467;
}

.sidebar li input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.collection-box .mint{
  background: #28B467;
  border-radius: 50px;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 7px 15px 6px 15px;
}

.picks .collection-box .img-wrap {
  position: relative;
  overflow: hidden;
}

.picks .collection-box .img-wrap > img{
  height: 320px;
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
}

.picks .collection-box .likes {
  position: absolute;
  right: 12px;
  top: 12px;
}

.likes {
  background: rgb(111, 111, 111);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
}

.likes img {
  margin-right: 5px;
}

.collection-box .coming-soon {
  position: absolute;
  left: 12px;
  top: 12px;
}

.coming-soon {
  background: #097238;
  border-radius: 20px;
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
  color: #fff;
  padding: 4px 19px;
}

.picks .collection-box .img-wrap .common-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.2s;
}

.picks .collection-box:hover .img-wrap .common-btn {
  opacity: 1;
  visibility: visible;
}

.collection-box .common-btn {
  min-width: 150px;
}

.collection-box .common-btn:hover {
  opacity: 0.9;
}

.collection-box .content {
  padding-top: 20px;
}

.collection-box .content h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  color: #39343A;
  margin-bottom: 17px;
}

.collection-box .content .creator-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collection-box .content .creator-wrap .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.collection-box .content .left>img {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 12px;
}

.collection-box .content .creator-wrap .creator-info span {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #666666;
  display: block;
  margin-bottom: 2px;
}

.collection-box .content .creator-wrap .creator-info h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #39343A;
}

.collection-box .content .protocol {
  background: #28B467;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 3px 10px 3px 11px;
}

.collection-box .content .bid-price {
  border-top: 1px solid #39343A;
  background: #39343A;
  padding-top: 15px;
  margin-top: 19px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: -16px;
  margin-bottom: -16px;
  width: calc(100% + 32px);
  padding: 12px;
}

.collection-box .content .bid-price .left span {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #28B467;
  margin-bottom: 2px;
  display: block;
}

.collection-box .content .bid-price .left h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: capitalize;
  color: #FFFFFF;
}

.collection-box .content .bid-price .left h4 span {
  display: inline-block;
  color: rgba(255,255,255,0.5);
}

.collection-box .content .bid-price a {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  display: flex;
  align-items: center;
}

.collection-box .content .bid-price a img {
  margin-right: 8px;
}

.explore-row{
  display: flex;
  flex-wrap: wrap;
}

.profile {
  margin-top: 160px;
}

.profile .inner {
  background: #313037;
  border-radius: 12px;
  overflow: hidden;
}

.profile .inner .inner-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 30px 0;
  min-height: 300px;
}

.profile .inner .img-wrap {
  width: 270px;
  z-index: 2;

}


.profile .inner .img-wrap img {
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
  height: 270px;
}

.profile .right {
  width: calc(100% - 270px);
  padding-left: 35px;
}

.profile .right div {
  height: 100%;
}

.profile .inner .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 35px;
  align-items: flex-start;
}

.profile .inner .content h2 {
  margin-bottom: 12px;
}

.profile .inner .content p {
  margin-bottom: 24px;
}

.wallet-address {
  border-radius: 50px;
  background: #EBEBEB;
  padding: 8px 20px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.wallet-address:hover {
  opacity: 0.9;
}

.wallet-address span {
  color: #7A798A;
  font-size: 13px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: calc(100% - 30px);
}

.wallet-address img {
  width: 16px;
}

.profile .right .wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: auto;
}

.profile .social-icons {
  display: flex;
}

.profile .social-icons li {
  margin-right: 12px;
}

.profile .social-icons li:last-child {
  margin-right: 0;
}

.profile .social-icons li a {
  background: #fff;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  transition: all ease-in-out 0.2s;
}

.profile .social-icons li a:hover {
  background: #28B467;
}

.profile .wrap .common-btn {
  font-size: 14px;
  padding: 9px 24px;
  margin-left: 32px;
}

.profile .profile-bottom {
  background: #343444;
  min-height: 74px;
  margin-top: -30px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.profile .profile-bottom nav {
  width: calc(100% - 300px);
  padding-left: 35px;
}

.profile .profile-bottom nav .nav-tabs {
  border: 0;
  padding-top: 24px;
  justify-content: space-around;
}

.profile .profile-bottom nav .nav-tabs button {
  border: 0;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.profile .profile-bottom nav .nav-tabs button:before {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: '';
  bottom: -23px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 10px solid #14141f;
  opacity: 0;
  transition: all ease-in-out 0.2s;
}

.profile .profile-bottom nav .nav-tabs button:hover:before {
  opacity: 1;
}

.profile .profile-bottom nav .nav-tabs button.active:before {
  opacity: 1;
}

.profile .tab-content {
  margin-top: 60px;
}

.profile .tab-content .filters-wrap {
  margin-bottom: 32px;
}

.profile .tab-content .filters-wrap form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile .tab-content .filters-wrap form .left {
  display: flex;
  align-items: center;
}

.profile .tab-content .filters-wrap form .left select {
  display: flex;
  align-items: center;
  background: #343444;
  border: 0 !important;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  background-image: url(../src/images/arrow-down.png);
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  padding: 11px 50px 11px 15px;
  box-shadow: none !important;
  height: 45px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper {
  background: #343444;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 45px;
  margin-left: 12px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper img {
  margin-right: 16px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper img:last-child {
  margin-right: 0;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-right: 16px;
}


.profile .tab-content .filters-wrap form .time {
  background: #343444;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 45px;
}

.profile .tab-content .filters-wrap form .time span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-right: 16px;
  opacity: 0.5;
}

.profile .tab-content .filters-wrap form .time span.active {
  opacity: 1;
}

.profile .tab-content .table-wrap {
  overflow: auto;
  margin-bottom: 70px;
}

.profile .tab-content table {
  width: 100%;
  min-width: 920px;
}


.profile .tab-content table th,
.profile .tab-content table td {
  color: #FFF;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 0;
}

.profile .tab-content table tr {
  border-bottom: 1px solid #1F1F2C;
}

.profile .tab-content table tbody tr:last-child {
  border-bottom: 0;
}

.profile .tab-content table td.percentage {
  color: #47A432;
}

.profile .tab-content table tr.loss td.percentage {
  color: #DF4949;
}

.profile .tab-content table th img {
  margin-left: 7px;
}

.profile .tab-content table td .img-wrap {
  position: relative;
  width: 56px;
  height: 56px;
  display: inline-block;
  margin-right: 16px;
}

.profile .tab-content table td .img-wrap img {
  border-radius: 15px;
  object-fit: cover;
  width: 56px;
  height: 56px;
}

.profile .tab-content table td .img-wrap img.top-seller-mark {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.profile .tab-content .signin {
  max-width: 100%;
  text-align: left;
}
.profile .tab-content .create .MuiFormControl-fullWidth{
  max-width: 690px;
  border-radius: 8px;
}
.profile .tab-content .create .MuiFormControl-fullWidth .MuiInputBase-colorPrimary{
  background: #e8e9eb;
  padding: 0;
  font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    border-radius: 8px;
    height: auto !important;
    margin-top: 0 !important;
}
.profile .tab-content .create .MuiFormControl-fullWidth .MuiInputBase-colorPrimary .MuiSelect-select{
  font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    padding: 13px 20px;
}
.profile .tab-content .create h2:first-child {
  margin-bottom: 50px;
}

.profile .tab-content .create h2 {
  margin-bottom: 12px;
  font-size: 40px;
  color: #39343A;
}

.profile .tab-content .create p {
  margin-bottom: 24px;
  color: #666666;
}

.profile .tab-content .create .form-group {
  margin-bottom: 48px;
}

.profile .tab-content .create .form-group>span {
  color: #666666;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.profile .tab-content .create .form-group input,
.profile .tab-content .create .form-group select,
.profile .tab-content .create .form-group textarea,
.profile .tab-content .create .form-group .upload-file {

  max-width: 690px;
}

form p a {
  color: #28B467;
}

.profile .tab-content .create .choose {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.profile .tab-content .create .choose .box {
  width: 49%;
  display: flex;
  align-items: center;
  padding: 24px;
  border: 1px solid #343444;
  border-radius: 8px;
}

.profile .tab-content .create .choose .box.active {
  background: #343444;
}

.profile .tab-content .create .choose .box img {
  width: 120px;
  border-radius: 8px;
  object-fit: cover;
}

.profile .tab-content .create .choose .box .info {
  width: calc(100% - 120px);
  padding-left: 32px;
}

.profile .tab-content .create .choose .box .info h3 {
  font-size: 20px;
  font-weight: 700;
  color: #39343A;
  margin-bottom: 16px;
}

.profile .tab-content .create .choose .box .info p {
  margin-bottom: 0;
  color: #666666;
  font-size: 14px;
}

.profile .tab-content .create .common-btn.create-btn {
  background: #28B467;
  width: 183px;
  height: 54px;
  border-radius: 100px;
}

.profile .tab-content .create {
  margin-bottom: 80px;
}

.profile .tab-content .create .check-box {
  width: 52px;
  height: 26px;
  background: #EBEBEB;
  border-radius: 50px;
}

.profile .tab-content .create .check-box input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 52px;
  height: 26px;
  background-color: #39343A !important;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
  margin: 0 !important;
  border: 0 !important;
}

.profile .tab-content .create input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 4px;
  left: 6px;
  background: #28B467;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.profile .tab-content .create input:checked[type="checkbox"]::after {
  left: 50%;
}

.profile .tab-content .create .form-group .label-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  max-width: 690px;
}

.profile .tab-content .create .form-group .label-head label {
  display: flex;
  align-items: center;
  margin: 0;
}

.profile.collection .profile-bottom nav {
  display: flex;
  align-items: center;
  padding: 0 35px;
}

.minting-btn {
  border-radius: 50px;
  background: #EBEBEB;
  border-radius: 100px;
  color: #7A798A;
  font-size: 14px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}
.minting h2{
  font-size: 40px;
  color: #39343A;
  text-align: center;
  margin-bottom: 12px;
}
.minting p{
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin-bottom: 20px;
}

.minting .info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666 !important;
  background-color: #e8e9eb !important;
  font-size: 13px;
  padding: 12px 20px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.minting button{
  margin: 25px auto 0;
  display: block;
}
.minting-btn:hover {
  opacity: 0.9;
}

.minting-btn span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #47A432;
  margin-right: 6px;
}

.profile .profile-bottom nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}

.profile .profile-bottom nav ul li p {
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
}

.profile .profile-bottom nav ul li span {
  color: #EBEBEB;
  font-size: 14px;
  font-weight: 400;
}

.profile .inner-wrap .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.upload-file {
  position: relative;
  border-radius: 8px;
  border: 1px solid #343444;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-file input {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  opacity: 0;
  top: 0;
}

.upload-file span {
  color: #666666;
  font-size: 14px;
  line-height: 22px;
}

.profile .common-btn{
  background: #28B467;
  color: #fff;
  border-radius: 100px;
  padding: 15px 35px;
}
.profile p img{
  display: inline;
}
.signin form input,
.signin form select,
.signin form textarea {
    border: 0 !important;
    border-radius: 8px;
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    background-color: #e8e9eb !important;
    height: 48px;
    width: 100%;
    display: block;
}
form label{
  display: block;
  margin-bottom: 16px;
  font-weight: 600;
}
.signin form select {
    background-color: #e8e9eb !important;
}

.signin form select option {
    color: #666666 !important;
}

.signin form textarea {
    resize: none;
    height: 120px;
}

.signin form input::placeholder,
.signin form textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
}

.signin form .form-check-input[type=checkbox] {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    padding: 0;
}

.signin form .form-check {
    max-width: 130px;
    width: 100%;
    margin-bottom: 33px;
}

.signin form .form-check label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 8px;
}

.minting-engine-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 120px 15px 80px;
}

.minting-engine-wrap .minting-engine{
  width: 448px;
}

.minting-engine-wrap .minting-engine .minting{
  position: relative;
  background:  #fff;
  border-radius: 24px;
  padding: 48px 24px;
  box-shadow: 0px 0px 64px rgba(0,0,0,0.04);
}

@media(min-width:992px) {
.ty-50{
  transform: translateY(-50%);
}
}
@media(max-width:1350px) {
  .sec-1 .desk-img{
    max-width: 550px !important;
  }
}
@media(max-width:1199px) {
  .picks .collection-box .img-wrap > img {
    height: 300px;
  }
  .sidebar{
    width: 190px;
  }

  .sec .inner .img-wrap{
    width: 45%;
  }

  .sec .inner .content{
    width: 52% !important;
  }

  .sec-1 .desk-img{
    max-width: 40% !important;
    top: 50%;
    margin-top: 100px;
  }
  .profile-wrap .boxes .box{
    width: calc(33.33% - 30px);
  }
  .profile-wrap .boxes .box img{
    height: 210px;
  }

  .minting-engine-wrap .minting-engine{
    width: 390px;
  }

  .minting-engine-wrap .left{
    width: 450px;
  }
}

@media(max-width:1100px) {
  .picks .collection-box .img-wrap > img {
    height: 290px;
  }
}

@media(max-width:992px) {
  .picks .collection-box .img-wrap > img {
    height: 340px;
  }

  .minting-engine-wrap{
    flex-direction: column;
  }

  .minting-engine-wrap .minting-engine{
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  }

  .minting-engine-wrap .left{
    width: 100%;
    margin-bottom: 40px;
  }

  .picks .collection-row .collection-box{
    width: calc(50% - 30px);
  }

  .profile .tab-content .create .choose {
    flex-direction: column;
}

.profile .tab-content .create .choose .box {
    width: 100%;
    margin-bottom: 24px;
}

.profile .tab-content .create .choose .box:last-child {
    margin-bottom: 0;
}

  .customDate[type="date"]::-webkit-calendar-picker-indicator {
    top: 1rem !important;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .popular-collection .collection-row .collection-box{
    width: calc(50% - 30px);
  }
}

.customDate[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../public/images/calender.svg') no-repeat;
  width: 14px;
  height: 16px;
  border-width: thin;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}


.custom-radio-wrap form .form-group label {
  appearance: none;
  background-color: white;
  border: 2px solid #262B42;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.custom-radio-wrap form .form-group .label-text {
  vertical-align: middle;
  cursor: pointer;
  padding-left: 10px;
}

.custom-radio-wrap form .form-group input {
  display: none;
  cursor: pointer;
}

.custom-radio-wrap form .form-group input:checked+label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 2px solid #D82E35;
}

.custom-radio-wrap form .form-group input:checked+label:after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #D82E35;
  box-shadow: inset 0px 0px 10px #D82E35;
  text-shadow: none;
  font-size: 32px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  display: none;
}
@media(max-width:991px) {
  .profile-wrap .boxes .box img {
    height: 170px;
  }
  .sec-1 .desk-img{
    top: 44%;
    margin-top: 0;
  }
}
@media(max-width:767px) {
  .picks .collection-box .img-wrap > img {
    height: 300px;
  }

  .picks .collection-box .img-wrap > img {
    height: 340px;
  }
  .btns button{
    width: 100px !important;
    height: 40px !important;
  }
  

  .sec-1:before{
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .sec .inner .img-wrap{
    width: 100%;
    margin-bottom: 30px;
  }

  .sec .inner .content{
    width: 100% !important;
    max-width: 100% !important;
  }

  .sec.sec-3 .inner, .sec.sec-6 .inner {
    flex-direction: column-reverse;
  }

  .sec-7 .box{
    padding: 32px 15px;
  }

  .profile-wrap .boxes .box {
    width: calc(50% - 30px);
  }
  .profile-wrap .boxes .box img {
    height: 200px;
  }
}

@media(max-width:640px) {
  .picks .collection-box .img-wrap > img {
    height: auto;
  }

  .picks .collection-row .collection-box{
    width: 100%;
  }

  .popup-wrap input{
    font-size: 16px !important;
  }
  .filewrap span{
    font-size: 16px !important;
  }
  .filewrap .upload-btn {
    font-size: 12px;
    padding: 2px 6px;
}
  nav .links li{
    font-size: 14px;
  }

  .logo-wrap{
    font-size: 0 !important;
  }

  nav .links{
    gap: 30px;
  }

  .btns button{
    width: 70px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
}

@media(max-width:575px) {
  .profile .tab-content .create .choose .box {
    padding: 20px;
}

.profile .tab-content .create .choose .box .info h3 {
    margin-bottom: 10px;
}
  .sidebar{
    width: 100%;
  }

  .picks{
    width: 100%;
    padding-left: 0;
  }
  .popular-collection .collection-row .collection-box{
    width: 100%;
  }

  footer .inner{
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    justify-content: flex-start;
  }

  footer .copyright{
    flex-direction: column;
    gap: 20px;
  }
  .profile-wrap .boxes .box {
    width: 100%
  }
  .profile-wrap .boxes .box img {
    height: auto;
  }
}